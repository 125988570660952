import { removeFabricObjectsByName } from "../bringFabricObjects";
import { fabric } from "fabric";
import testSVG from '../../../../assets/img/testSVG.svg'


const canvasBGimage = (
    canvas,
    projectSettings,
    addNewFloor,
    selFloorPlanDtls,
    canvasContainerRef,
    activeTab,
    imgSrc,
    zoom,
    bgColor,
    scaleX,
    scaleY,
    zoomInOut,
    svgFile
) => {
    if (imgSrc && canvas.current) {
        const isSvg = imgSrc.endsWith('.svg')
        console.log(isSvg, 'svg_Blob')
        if (isSvg) {
            const svg_Blob = selFloorPlanDtls?.get_svg ?? ''
            // console.log(svg_Blob,'svg_Blob')
            /* Add svg as object */
            removeFabricObjectsByName(canvas, 'svg_refImage');
            fabric.loadSVGFromString(svg_Blob, function (objects, options) {
                const svg = fabric.util.groupSVGElements(objects, options);
                let scaleFactor = zoom ?? zoomInOut;
                svg.set({
                    selectable: false,
                    originX: "center",
                    originY: "center",
                    left: selFloorPlanDtls?.width / 2,
                    top: selFloorPlanDtls?.height / 2,
                    scaleX: scaleFactor,
                    scaleY: scaleFactor,
                    id: 'svg_refImage',
                    name: 'svg_refImage'
                })
                canvas.current.add(svg);
                canvas.current.sendToBack(svg);
                canvas.current.renderAll();
            });
            canvas.current.setBackgroundImage(null, canvas.current.renderAll.bind(canvas.current));

            // /* Add svg setBackgroundImage */
            // fabric.loadSVGFromString(svg_Blob, function (objects, options) {
            //     const svgGroup = fabric.util.groupSVGElements(objects, options);
            //     console.log(svgGroup, 'setBackgroundImage')
            //     let canvasAspectRatio = selFloorPlanDtls?.width / selFloorPlanDtls?.height;
            //     let imgAspectRatio = svgGroup.width / svgGroup.height;
            //     let scaleFactor = zoom ?? zoomInOut;
            //     console.log(scaleFactor, 'scaleFactor')
            //     svgGroup.scale(scaleFactor).set({
            //         originX: "center",
            //         originY: "center",
            //         left: selFloorPlanDtls?.width / 2,
            //         top: selFloorPlanDtls?.height / 2,
            //         scaleX: scaleFactor,
            //         scaleY: scaleFactor,
            //     });
            //     canvas.current.setBackgroundImage(
            //         svgGroup,
            //         canvas.current.renderAll.bind(canvas.current),
            //         {
            //             backgroundImageStretch: false,
            //         }
            //     );
            // })
        } else {
            console.log(imgSrc, 'svg_Blob_else')
            removeFabricObjectsByName(canvas, 'svg_refImage');
            fabric.Image.fromURL(imgSrc, (img) => {
                let canvasAspectRatio = selFloorPlanDtls?.width / selFloorPlanDtls?.height;
                let imgAspectRatio = img.width / img.height;
                let scaleFactor = zoom ?? zoomInOut;

                if (canvasAspectRatio > imgAspectRatio) {
                    scaleFactor = selFloorPlanDtls?.width / img.width;
                } else {
                    scaleFactor = selFloorPlanDtls?.height / img.height;
                }

                const newScaleX = img.scaleX * (scaleX ?? zoomInOut);
                const newScaleY = img.scaleY * (scaleY ?? zoomInOut);

                img.scale(scaleFactor).set({
                    originX: "center",
                    originY: "center",
                    scaleX: zoom ?? newScaleX ?? zoomInOut,
                    scaleY: zoom ?? newScaleY ?? zoomInOut,
                    left: selFloorPlanDtls?.width / 2,
                    top: selFloorPlanDtls?.height / 2
                });

                // Set the image as canvas background
                canvas.current.setBackgroundImage(
                    img,
                    canvas.current.renderAll.bind(canvas.current),
                    {
                        backgroundImageStretch: false,
                    }
                );
                // canvas.current.centerObject(img);
                removeFabricObjectsByName(canvas, "backgroundRect");
                const backgroundRect = new fabric.Rect({
                    height: canvasContainerRef.current.clientHeight,
                    width: canvasContainerRef.current.clientWidth,
                    left: 0,
                    top: 0,
                    // fill: filterColor,
                    fill: 'transparent',
                    selectable: false,
                    name: "backgroundRect",
                    hoverCursor: activeTab == "floorDetails" ? "default" : "pointer"
                });
                canvas.current.add(backgroundRect);
                canvas.current.sendToBack(backgroundRect);
            });
        }
    }
    else {
        canvas.current.setBackgroundImage(
            null,
            canvas.current.renderAll.bind(canvas.current)
        );
        removeFabricObjectsByName(canvas, 'svg_refImage');
        removeFabricObjectsByName(canvas, "backgroundRect");
        const backgroundRect = new fabric.Rect({
            height: canvasContainerRef.current.clientHeight,
            width: canvasContainerRef.current.clientWidth,
            left: 0,
            top: 0,
            // fill: filterColor,
            fill: 'transparent',
            selectable: false,
            name: "backgroundRect",
            hoverCursor: activeTab == "floorDetails" ? "default" : "pointer"
        });
        canvas.current.add(backgroundRect);
        canvas.current.sendToBack(backgroundRect);
        canvas.current.renderAll()
    }
};


const loadSvgAsBackground = async (canvas, selFloorPlanDtls) => {
    fabric.Image.fromURL(selFloorPlanDtls?.refImg, (img) => {
        let canvasAspectRatio = selFloorPlanDtls?.width / selFloorPlanDtls?.height;
        let imgAspectRatio = img.width / img.height;
        let scaleFactor = zoom ?? zoomInOut;

        if (canvasAspectRatio > imgAspectRatio) {
            scaleFactor = selFloorPlanDtls?.width / img.width;
        } else {
            scaleFactor = selFloorPlanDtls?.height / img.height;
        }

        const newScaleX = img.scaleX * (scaleX ?? zoomInOut);
        const newScaleY = img.scaleY * (scaleY ?? zoomInOut);

        img.scale(scaleFactor).set({
            originX: "center",
            originY: "center",
            scaleX: zoom ?? newScaleX ?? zoomInOut,
            scaleY: zoom ?? newScaleY ?? zoomInOut,
            left: selFloorPlanDtls?.width / 2,
            top: selFloorPlanDtls?.height / 2
        });

        // Set the image as canvas background
        canvas.current.setBackgroundImage(
            img,
            canvas.current.renderAll.bind(canvas.current),
            {
                backgroundImageStretch: false,
            }
        );
        // canvas.current.centerObject(img);
        removeFabricObjectsByName(canvas, "backgroundRect");
        const backgroundRect = new fabric.Rect({
            height: canvasContainerRef.current.clientHeight,
            width: canvasContainerRef.current.clientWidth,
            left: 0,
            top: 0,
            // fill: filterColor,
            fill: 'transparent',
            selectable: false,
            name: "backgroundRect",
            hoverCursor: activeTab == "floorDetails" ? "default" : "pointer"
        });
        canvas.current.add(backgroundRect);
        canvas.current.sendToBack(backgroundRect);
    });
};

export default canvasBGimage;